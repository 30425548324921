html,
body {
  height: 100%;
  min-height: 100%;
}

body {
  font-family: 'Lato', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background-color: #343a40;
}

header {
  padding-top: 2rem!important;
  padding-bottom: 0!important;
}

header {
  background-image: url('../img/bg-hands.png');
  background-repeat: repeat-x;
  background-position: bottom center;
  background-size: 600px;
  padding-bottom: 225px!important;
}

header h1 {
  font-size: 50pt;
}

header p {
  max-width: 800px;
  margin: 50px auto;
}

section {
  padding: 5rem 0;
}

section h2 {
  font-size: 30pt;
  margin-top: 0rem;
  margin-bottom: 5rem;
}

section h3 {
  margin: 2rem 0;
}

.how-does-it-work {
  background-color: #eee;
}

.how-does-it-work i {
  font-size: 4.5rem;
}

.call-to-action {
  background-color: #343a40;
}

.plan {
  border: 1px solid #aaa;
  margin: 0 15px;
  padding-bottom: 15px;
  border-radius: 15px;
}

.plan .price {
  font-size: 18pt;
}

.plan .features {
  margin: 0 auto;
  color: #aaa;
}

.plan .divider {
  margin: 0 auto;
  margin-top: 25px;
  padding-top: 25px;
  border-top: 1px solid #666;
  max-width: 150px;
}

footer {
  padding-top: 0;
  padding-bottom: 0;
}

footer .bg-footer {
  height: 250px;
  margin: 25px 0;
  background-color: #343a40;
  background-image: url('../img/bg-group.png');
  background-repeat: no-repeat;
  background-position: top center;
  background-size: contain;
}

footer .copyright {
  color: #999;
}

/* mobile */
@media (max-width: 768px) {
  header {
    text-align: center;
    padding-top: 5rem;
    padding-bottom: 17rem;
    background-repeat: no-repeat;
  }

  .how-does-it-work {
    padding-bottom: 0;
  }

  .col {
    margin-bottom: 2rem;
    flex: auto;
  }

  .call-to-action {
    background-position: bottom center;
  }
}
